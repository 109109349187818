module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"amyshackles-gatsby-site","short_name":"amyshackles","start_url":"/","background_color":"#7700d5","theme_color":"#7700d5","display":"standalone","icon":"src/images/apple-touch-icon2.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c5000195fa57dddf0c5ee278970f828c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
